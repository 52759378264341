<script context="module">


    import moment from 'moment';

	//====================================================================================
	// labelNegeri
	//====================================================================================
	export function labelNegeri(negeri, stateid){
		if (!negeri) return '';
		return [5,14,15,16].includes(stateid)
			? negeri
			: 'Negeri '+negeri
	}

	//====================================================================================
	// labelDaerah
	//====================================================================================
	export function labelDaerah(daerah){
		daerah = (daerah||'').replace(/^WP\s+/,'');
		return daerah;
	}

	export function labelDaerahPrefix(daerah, stateid){
		daerah = labelDaerah(daerah);
		if (stateid){
			daerah = [14,15,16].includes(stateid) ? daerah : 'Daerah '+daerah;
		}
		return daerah;
	}

	//====================================================================================
	// labelPremise
	//====================================================================================
	export function labelPremise(premise){
		premise = (premise||'').replace(/\(\s*/,'(');
		premise = premise.replace(/\s*\)/,')');
		return decodeURIComponent(premise);
	}

	//====================================================================================
	// labelAddress
	//====================================================================================
	export function labelAddress(address){
		address = (address||'').replace(/\s+/g,' ');
		address = address.replace(/\,\s*/g,', ');
		return address;
	}

	//====================================================================================
	// labelItem
	//====================================================================================
	export function labelItem(item){
		item = (item||'').replace(/\s+/g,' ');
		item = item.replace(/\,\s*/g,', ');
		item = item.replace(/\(\s*/g,'(');
		item = item.replace(/\s*\)/g,')');
		return item;
	}

	//====================================================================================
	// labelDateRange
	//====================================================================================
    export function labelDateRange(date_from, date_to){
        if (date_from && date_to) {
            let dt1 = moment(date_from),
                dt2 = moment(date_to);

            if (dt1 && dt2 && dt1 < dt2) {

                if (dt1.format('YYYYMMDD')==dt2.format('YYYYMMDD')) {
                    return [
                        '<span class="whitespace-nowrap">'+'minggu ke-'+dt1.isoWeek()+'</span>',
                        '<span class="whitespace-nowrap">'+'tahun '+dt1.year()+'</span>',
                        'dari',
                        '<span class="whitespace-nowrap">'+dt1.format('D MMMM YYYY')+'</span>'
                    ].join(' ');
                }else if (dt1.format('YYYYMM')==dt2.format('YYYYMM')) {
                    return [
                        '<span class="whitespace-nowrap">'+'minggu ke-'+dt1.isoWeek()+'</span>',
                        '<span class="whitespace-nowrap">'+'tahun '+dt1.year()+'</span>',
                        '<span class="whitespace-nowrap">'+'dari',
                        dt1.format('D'),
                        'hingga',
                        dt2.format('D MMMM YYYY')+'</span>'
                    ].join(' ');
                }else if (dt1.format('YYYY')==dt2.format('YYYY')) {
                    return [
                        '<span class="whitespace-nowrap">'+'minggu ke-'+dt1.isoWeek()+'</span>',
                        '<span class="whitespace-nowrap">'+'tahun '+dt1.year()+'</span>',
                        '<span class="whitespace-nowrap">'+'dari',
                        dt1.format('D MMMM')+'</span>',
                        'hingga',
                        '<span class="whitespace-nowrap">'+dt2.format('D MMMM YYYY')+'</span>'
                    ].join(' ');
                }else {
                    return [
                        '<span class="whitespace-nowrap">'+'minggu ke-'+dt1.isoWeek()+'</span>',
                        '<span class="whitespace-nowrap">'+'tahun '+dt1.year()+'</span>',
                        '<span class="whitespace-nowrap">'+'dari',
                        dt1.format('D MMMM YYYY')+'</span>',
                        'hingga',
                        '<span class="whitespace-nowrap">'+dt2.format('D MMMM YYYY')+'</span>'
                    ].join(' ');
                }

            }  else {
                if (dt2 < dt1) {

                    return [
                        '<span class="whitespace-nowrap">'+'minggu ke-'+dt2.isoWeek()+'</span>',
                        '<span class="whitespace-nowrap">'+'tahun '+dt2.year()+'</span>',
                        'sehingga',
                        '<span class="whitespace-nowrap">'+dt2.format('D MMMM YYYY')+'</span>'
                    ].join(' ');

                }else {

                    return [
                        '<span class="whitespace-nowrap">'+'minggu ke-'+dt1.isoWeek()+'</span>',
                        '<span class="whitespace-nowrap">'+'tahun '+dt1.year()+'</span>',
                        'dari',
                        '<span class="whitespace-nowrap">'+dt1.format('D MMMM YYYY')+'</span>',
                    ].join(' ');

                }

            }

        }else {

            return '<span class="whitespace-nowrap">'+moment(date_from).format('D MMMM YYYY')+ '</span>';

        }

    }



	//====================================================================================
	// url
	//====================================================================================

    export function urlDebug( dbg, url ){
		return dbg
			? url+'?dbg=1'
			: url
	}

	//------------------------------------------------------------------------------------
	// urlChain
	//------------------------------------------------------------------------------------

    // /chain/giant
    export function urlChain(
		chain
    ) {
        return [
			"/rangkaian",
			makeURLKey(chain)
		].join('/')
    }

    // /chain/giant/negeri/pahang-6
    export function urlChainNegeri(
		chain,
		negeri, stateid
    ) {
        return [
			"/rangkaian",
			makeURLKey(chain),
			"negeri", makeURLKey(negeri)+'-'+stateid,
		].join('/')
    }


    // /chain/giant/negeri/pahang-6/kuantan-7
    export function urlChainNegeriDaerah(
		chain,
		negeri, stateid,
		daerah, districtid
    ) {
        return [
			"/rangkaian",
			makeURLKey(chain),
			"negeri", makeURLKey(negeri)+'-'+stateid,
			"daerah", makeURLKey(daerah)+'-'+districtid,
		].join('/')
    }




	//------------------------------------------------------------------------------------
	// urlNegeri
	//------------------------------------------------------------------------------------

    // /negeri/pahang-6
    export function urlNegeri(
        negeri, stateid
    ) {
        return stateid
			? 	[
					"/negeri",
					makeURLKey(negeri)+'-'+stateid
				].join('/')
			: '/'
    }


	//------------------------------------------------------------------------------------
	// urlNegeriDaerah
	//------------------------------------------------------------------------------------

    export function urlNegeriDaerah(
        negeri, stateid,
        daerah, districtid
    ) {
		return stateid && districtid
		?	[
				"/negeri", makeURLKey(negeri)+'-'+stateid,
				"daerah", makeURLKey(daerah)+'-'+districtid,
			].join('/')
		: stateid
			?	[
					"/negeri", makeURLKey(negeri)+'-'+stateid,
				].join('/')
			: 	'/'
    }

	//------------------------------------------------------------------------------------
	// urlNegeriDaerahGroup
	//------------------------------------------------------------------------------------

	export function urlNegeriDaerahGroup(
		negeri, stateid,
		daerah, districtid,
		group
	) {


		return stateid && districtid
		? 	[
				"/negeri", makeURLKey(negeri)+'-'+stateid,
				"daerah", makeURLKey(daerah)+'-'+districtid,
				"kategori", makeURLCategory(group),
			].join('/')
		: stateid
			? 	[
					"/negeri", makeURLKey(negeri)+'-'+stateid,
					"kategori", makeURLCategory(group),
				].join('/')
			: 	[
					"/kategori", makeURLCategory(group),
				].join('/')
	}

	//------------------------------------------------------------------------------------
	// urlNegeriDaerahGroupCategory
	//------------------------------------------------------------------------------------

	export function urlNegeriDaerahGroupCategory(
		negeri, stateid,
		daerah, districtid,
		group,
		kategori
	) {


		return [
			"/negeri", makeURLKey(negeri)+'-'+stateid,
			"daerah", makeURLKey(daerah)+'-'+districtid,
			"kategori", makeURLCategory(group),
			makeURLCategory(kategori)
		].join('/')
	}

	//------------------------------------------------------------------------------------
	// urlNegeriDaerahGroupCategory
	//------------------------------------------------------------------------------------

	export function urlNegeriDaerahGroupCategorySubcategory(
		negeri, stateid,
		daerah, districtid,
		group,
		kategori,
		subkategori
	) {
		return [
			"/negeri", makeURLKey(negeri)+'-'+stateid,
			"daerah", makeURLKey(daerah)+'-'+districtid,
			"kategori", makeURLCategory(group),
			makeURLCategory(kategori),
			makeURLCategory(subkategori)
		].join('/')
	}



	//------------------------------------------------------------------------------------
	// /negeri/group
	//------------------------------------------------------------------------------------

    export function urlNegeriGroup(
        negeri, stateid,
        group
    ) {
					return [
							"/negeri",
							makeURLKey(negeri)+'-'+stateid,
							"kategori",
							makeURLCategory(group),
					].join('/')
					.replace(/\/+/g, '/')
    }


    export function urlNegeriGroupCategory(
        negeri, stateid,
        group,
				kategori
    ) {
					return [
						"/negeri",
						makeURLKey(negeri)+'-'+stateid,
						"kategori",
						makeURLCategory(group),
						makeURLCategory(kategori)
				].join('/')
						.replace(/\/+/g, '/')
    }


    export function urlNegeriGroupCategorySubcategory(
        negeri, 
				stateid,
        group,
				kategori,
				subkategori
    ) {
					return [
						"/negeri",
						makeURLKey(negeri)+'-'+stateid,
						"kategori",
						makeURLCategory(group),
						makeURLCategory(kategori),
						makeURLCategory(subkategori),
				].join('/')
						.replace(/\/+/g, '/')
    }




	//------------------------------------------------------------------------------------
	// /kedai
	//------------------------------------------------------------------------------------

    // /Kedai/BsFreshmartMersing-20771/LimauKasturi-1928

	export function urlKedai(
        premise, premise_code
    ) {
        return [
            "/kedai",
            makeURLKey(premise)+'-'+(premise_code||0)
        ].join('/')
    }


    // /barang/KacangBuncis-97

	export function urlKedaiBarang(
        premise, premise_code,
        item, item_code
    ) {
        return [
            "/kedai", makeURLKey(premise)+'-'+(premise_code||0),
			'barang', makeURLKey(item)+'-'+(item_code||0),
        ].join('/')
    }


	export function urlKedaiGroup(
        premise, premise_code,
		group
    ) {
        return [
            "/kedai", makeURLKey(premise)+'-'+(premise_code||0),
			'kategori', makeURLCategory(group),
        ].join('/')
    }


	export function urlKedaiGroupCategory(
        premise, premise_code,
		group,
		category
    ) {
        return [
            "/kedai", makeURLKey(premise)+'-'+(premise_code||0),
			'kategori', makeURLCategory(group),
			makeURLCategory(category),
        ].join('/')
    }



	export function urlKedaiGroupCategorySubcategory(
        premise, premise_code,
		group,
		category,
		subcategory
    ) {
        return [
            "/kedai", makeURLKey(premise)+'-'+(premise_code||0),
			'kategori', makeURLCategory(group),
			makeURLCategory(category),
			makeURLCategory(subcategory),
        ].join('/')
    }


	//------------------------------------------------------------------------------------
	// /barang
	//------------------------------------------------------------------------------------

    // /barang/KacangBuncis-97

	export function urlBarang(
        item, item_code
    ) {
        return [
            "/barang",
            makeURLKey(item)+'-'+(item_code||0)
        ].join('/')
    }


    // /barang/KacangBuncis-97/Terengganu-11

	export function urlBarangNegeri(
        item, item_code,
        negeri, stateid
    ) {
        return [
            "/barang",
            makeURLKey(item)+'-'+(item_code||0),
			'negeri',
            makeURLKey(negeri)+'-'+(stateid||0),
        ].join('/')
    }

    // /barang/KacangBuncis-97/Terengganu-11/Setiu-8

	export function urlBarangNegeriDaerah(
        item, item_code,
        negeri, stateid,
        daerah, districtid
    ) {
        return [
            "/barang",
            makeURLKey(item)+'-'+(item_code||0),
			'negeri',
            makeURLKey(negeri)+'-'+(stateid||0),
            makeURLKey(daerah)+'-'+(districtid||0)
        ].join('/')
    }

	//------------------------------------------------------------------------------------
	// /kategori
	//------------------------------------------------------------------------------------

	// /kategori/barangan_segar
	export function urlGroup(
				group
		) {
				return [
						"/kategori",
						makeURLCategory(group)
				].join('/')
		}

	// /kategori/barangan_segar
	export function urlGroupCategory(
				group,
				kategori
		) {
				return [
						"/kategori",
						makeURLCategory(group),
						makeURLCategory(kategori),
				].join('/')
		}

	export function urlGroupCategorySubcategory(
				group,
				kategori,
				subkategori
		) {
				return [
						"/kategori",
						makeURLCategory(group),
						makeURLCategory(kategori),
						makeURLCategory(subkategori),
				].join('/')
	}


	//====================================================================================
	// makeURLCategory
	//====================================================================================

	export function makeURLCategory(str='') {
		if (typeof str!='string') str='';

		str = str.trim();

		return (str||'')
						.split(/\W+/)
						.filter(d=>!!d)
						.join('_')
						.toLowerCase();
	}

	//====================================================================================
	// makeURLKey
	//====================================================================================

	export function makeURLKey(str='',length=3) {
		if (!length) length=3;
		if (typeof str!='string') str='';

		str = str.trim();

        // str = shortenWord(str);

        // str = (str||'').replace(/&GE;/,'');
        str = str.replace(/W\.P\./i,'');


		return (str||'')
						.split(/\W+/)
						.filter(d=>d.length>2||typeof +d=='number')
						.filter(d=>!['ANTARA','SAIZ','HINGGA','EKOR','SEKILOGRAM'].includes(d))
						.filter(d=>d)
						.slice(0,length)
						.join('_')
						.toLowerCase();
	}

	function toTitleCase(str) {

		if (typeof str!='string') str='';
		str = str.trim();

		return str.replace(
			/\w\S*/g,
			function(txt) {
				return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
			}
		);
	}


	//====================================================================================
	// decodeURLKey
	//====================================================================================
	export function decodeURLKey(str) {
		return str.split(/(?=[A-Z])/).join(' ')
	}


	//====================================================================================
	// plusCodeEncode
	//====================================================================================
	export function shortenWord(str,size){

        str = (str||'');


        str = str.replace(/LOTUS.*?S/,'LOTUS`S');
        str = str.replace(/MAMA CAF.*?$/,'MAMA CAFE');

            //LOTUSâS IOI MALL PUTRAJAYA
        str = str.replace(/â\s*/,'`');
        str = str.replace(/&GE;/,'');

        str = str.replace(/\â/,'');
        str = str.replace(/\/,'');

        str = str.replace(/\&GE;/i,'>');
        str = str.replace(/\&LE;/i,'<');

        str = str.replace(/\s*\/\s*/g,' / ');

        str = str.replace(/\s+/g,' ');

        str.trim();

		let length = size=='sm'
									? 15
									: size == 'md'
										? 25
										: 300;

		if ((str||'').length>length)	{
			let w=[];
			(str||'').split(/\s+/)
				.forEach(d=>{
					if (w.join(' ').length<=length) {
						w.push(d);
					}
				});
			if (!w.length) w=(str||'').trim().split(/\s+/).slice(0,1);
			return w.join(' ').trim()
		}else{
			return str;
		}
	}




	//====================================================================================
	// hargaUnit
	//====================================================================================
	export function hargaUnit(item, unit, size){

		item = shortenWord(item);
		return [item,unit].join('-');

	}



	//====================================================================================
	// cleanText
	//====================================================================================

	export function cleanText(str)	{
		str = str.replace(/\s*\/\s*/g,' / ');
		str = str.replace(/\s*\(\s*/g,' (');
		str = str.replace(/\s*\)\s*/g,') ');
		str = str.replace(/[^\u0000-\u007F]+/g,' ');
		str = str.trim();
		return str;
	}






	//====================================================================================
	// heDecode
	//====================================================================================
	// import * as he from 'he';
	// import { browser } from '$app/environment';

	// export function heDecode(str){
	// 	if (str)	{
	// 		if (browser) {
	// 			return he.decode(str)
	// 		}else {
	// 			return str
	// 		}
	// 	}else {
	// 		return '';
	// 	}
	// }


	//====================================================================================
	// plusCodeEncode
	//====================================================================================

	// import * as pluscodes from 'pluscodes';

	// export const plusCodeEncode = (latlon,accuracy) => {
	// 	return pluscodes.encode({
	// 		latitude: latlon[0],
	// 		longitude: latlon[1]
	// 	}, accuracy)
	// };

	// export const plusCodeDecode = (plusCode) => {
	// 	return pluscodes.decode(plusCode)
	// };

	// export const pluscode2latlon = ( param_pluscode ) => {
	// 	//return [plusCodeDecode($page.params['pluscode'].split('-').slice(-1)[0] )].map(d=>[ d.latitude, d.longitude ])[0]
	// 	return [plusCodeDecode(param_pluscode.split('-').slice(-1)[0] )].map(d=>[ d.latitude, d.longitude ])[0]
	// }

	//====================================================================================
	// debounce
	//====================================================================================

	export const debounce = (func, delay) => {
		let timer;
		return function () {
			const context = this;
			const args = arguments;
			clearTimeout(timer);
			timer = setTimeout(() => func.apply(context, args), delay);
		};
	};



	//====================================================================================
	// leaflet
	//====================================================================================
	// Cannot reference store value inside <script context="module">

	// export function onLocationError(e) {
	// 	filterLocationProgress = false;
	// 	filterLocationError = e.message;
	// 	$M.user.geolocation = false;
	//   //alert(e.message);
	// };


	// export function onLocationFound(e) {

	// 	console.log('e.latlng', e.latlng);
	// 	if (e.latlng)	{

	// 		$M.user.geolocation = true;

	// 		$M.user.location.longitude = e.latlng.lng;
	// 		$M.user.location.latitude = e.latlng.lat;

	// 		let dsl = {
	// 			longitude	: e.latlng.lng,
	// 			latitude	: e.latlng.lat,
	// 		};

	// 		d3.json('/req/reverse_geocoding', {
	// 			headers: {"Content-type": "application/json; charset=UTF-8"},
	// 			method: 'POST',
	// 			body:JSON.stringify(dsl)
	// 		})
	// 		.then(res=>{
	// 	//				console.log('res', res);
	// 			if (res?.geo_district)	{
	// 				$M.user.location = Object.assign($M.user.location, res);
	// 				$M.location = {
	// 					longitude		: e.latlng.lng,
	// 					latitude		: e.latlng.lat,
	// 					district		: $M.user.location?.geo_district?.district,
	// 					state				: $M.user.location?.geo_district?.state,
	// 					districtid	: $M.user.location?.geo_district?.code_district,
	// 					stateid			: $M.user.location?.geo_district?.code_state,
	// 					source			: 'geolocation',
	// 				};
	// 				console.log('$M.location', $M.location);
	// 				window.localStorage.setItem('_location', JSON.stringify($M.location))
	// 			}

	// 			filterLocationProgress = false;
	// 			toggleLocation = false;

	// 		});

	// 	}

	// 	filterLocationError = null;

	// };


	//====================================================================================
	//
	//====================================================================================


</script>
